<template>
  <div class="mobileTimeTrackingTable">
    <DataTable
      class="p-datatable-sm"
      :rowClass="rowClass"
      :value="timeTrackings"
      :rowHover="true"
      :loading="isLoading"
      editMode="cell"
      dataKey="number"
      :lazy="true"
      :totalRecords="getTimeTrackingCount"
      stateStorage="local"
      @cell-edit-complete="onCellEditComplete"
      responsiveLayout="scroll"
      :sortField="tableState.sortField"
      :sortOrder="tableState.sortOrder"
      :rows="tableState.pagination.rowsPerPage"
      :first="tableState.pagination.page * tableState.pagination.rowsPerPage"
      @page="onPage"
      @sort="onSort"
      :paginator="true"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 20, 50, 100]"
    >
      <Column field="createdAt" :header="$t('day')" sortable>
        <template #body="slotProps">
          <span v-if="slotProps.data.createdAt">
            {{ formatDateWithDots(slotProps.data.createdAt) }}
          </span>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column
        field="startTime"
        :header="$t('start')"
        headerClass="center"
        className="center"
        sortable
      >
        <template #body="slotProps">
          <strong> {{ $dayjs(slotProps.data[slotProps.column.field]).format('HH:mm') }}</strong>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>

      <Column field="stopTime" :header="$t('end')" headerClass="center" className="center" sortable>
        <template #body="slotProps">
          <strong v-if="slotProps.data[slotProps.column.field]">
            {{ $dayjs(slotProps.data[slotProps.column.field]).format('HH:mm') }}
          </strong>
          <strong v-else>
            <HollowDotsSpinner :animation-duration="6000" size="10" color="#495057" />
          </strong>
        </template>

        <template #filter="{filterModel,filterCallback}">
          <Calendar
            v-model="filterModel.value"
            @input="filterCallback()"
            selectionMode="single"
            dateFormat="dd.mm.yy"
            placeholder=">="
          >
          </Calendar>
        </template>
      </Column>
      <Column field="duration" :header="$t('duration')" sortable>
        <template #body="slotProps">
          <span v-if="slotProps.data.duration">
            {{ formatDuration(slotProps.data.duration) }}
          </span>
        </template>
      </Column>

      <Column field="timeTrackingState" :header="$t('state')" :styles="{ width: '7%' }" sortable>
        <template #body="slotProps">
          <b-badge :variant="getTimeTrackingStateColor(slotProps.data.timeTrackingState)">
            {{ $t(slotProps.data.timeTrackingState) }}
          </b-badge>
        </template>
        <template #editor="{ data, field }">
          <Dropdown
            v-model="data[field]"
            :options="getEnumValues('TimeTrackingState')"
            :placeholder="$t('select')"
          >
            <template #option="slotProps">
              <b-badge :variant="getTimeTrackingStateColor(slotProps.option)">
                {{ $t(slotProps.option) }}
              </b-badge>
            </template>
            <template #value="slotProps">
              <b-badge :variant="getTimeTrackingStateColor(slotProps.value)">
                {{ $t(slotProps.value) }}
              </b-badge>
            </template>
          </Dropdown>
        </template>
      </Column>
      <Column field="project.customer.lastname" :header="$t('customer')"></Column>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import { FilterMatchMode } from 'primevue/api';
import { getTimeTrackingStateColor } from '@/helpers/colors';
import { HollowDotsSpinner } from 'epic-spinners';

export default {
  name: 'TimeTrackingTable',
  components: {
    DataTable,
    Column,
    Calendar,
    Dropdown,
    HollowDotsSpinner,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      filteredUsers: [],
      isEditingAllowed: true,
      filteredProjects: [],
      projectsFilter: {
        pagination: {
          page: 0,
          rowsPerPage: 50,
        },
        sortField: 'number',
        sortOrder: -1,
        filterName: 'time-tracking-table-filters',
      },

      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
      },
      defaultCustomFilters: {
        taskStates: [],
      },

      tableState: {
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        filters: {
          createdAt: { value: null, matchMode: FilterMatchMode.DATE },
        },
        sortField: 'createdAt',
        sortOrder: -1,
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['isAdmin', 'getCurrentUser', 'isClient']),
    ...mapGetters([
      'getTimeTrackings',
      'getEnumValues',
      'isLoading',
      'getUser',
      'getTimeTrackingCount',
    ]),

    user() {
      return this.getUser(this.getCurrentUser.id);
    },

    timeTrackings() {
      return this.getTimeTrackings;
    },

    isProjectContext() {
      return this.project;
    },
    timeTrackingStates() {
      const timeTrackingStates = this.getEnumValues('TimeTrackingStates').map(
        (timeTrackingState) => {
          return { value: timeTrackingState, label: this.$t(timeTrackingStates) };
        }
      );
      return timeTrackingStates;
    },
  },
  methods: {
    ...mapActions(['fetchEnumValues', 'fetchTimeTrackingsByUser', 'updateTimeTracking']),
    formatDateWithDots(datetime) {
      const dateObj = new Date(datetime);
      const day = String(dateObj.getDate()).padStart(2, '0');
      const month = String(dateObj.getMonth() + 1).padStart(2, '0');
      const year = dateObj.getFullYear();
      return `${day}.${month}.${year}`;
    },
    async save(timeTracking) {
      if (this.isProjectContext) {
        timeTracking.project = this.project;
      }

      if (timeTracking.number === '_new') {
        await this.createTimeTracking(timeTracking);
      } else {
        await this.updateTimeTracking({
          timeTracking,
        });
        await this.loadTimeTrackingData();
      }
    },

    async loadTimeTrackingData() {
      try {
        await this.fetchTimeTrackingsByUser({
          userId: this.user.id,
          page: this.tableState.pagination.page,
          pageSize: this.tableState.pagination.rowsPerPage,
          sortField: this.tableState.sortField,
          sortOrder: this.tableState.sortOrder,
        });
      } catch (error) {
        console.error('Fehler beim Laden der Zeitverfolgungsdaten:', error);
      }
    },
    /**
     * make new table entries identifiable by background color
     */
    rowClass(data) {
      return data.number === '_new' ? 'new' : null;
    },

    formatDuration(minutes) {
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`;
    },
    /**
     * send task data to server after editing a cell
     * @param {*} event
     */
    async onCellEditComplete(event) {
      let { data, newValue, field } = event;

      data[field] = newValue;

      this.save(data);
    },
    async onSelectState(row) {
      const { data } = row;
      this.save(data);
    },
    async onPage(event) {
      this.tableState.pagination.page = event.page;
      this.tableState.pagination.rowsPerPage = event.rows;
      this.loadTimeTrackingData();
    },

    async onSort(event) {
      this.tableState.sortField = event.sortField;
      this.tableState.sortOrder = event.sortOrder;
      this.loadTimeTrackingData();
    },
    getTimeTrackingStateColor,
  },
  async onPage(event) {
    this.tableState.pagination.page = event.page;
    this.tableState.pagination.rowsPerPage = event.rows;
    await this.loadTimeTrackingData();
  },

  async onSort(event) {
    this.tableState.sortField = event.sortField;
    this.tableState.sortOrder = event.sortOrder;
    await this.loadTimeTrackingData();
  },

  /**
   * Fetch project data from graphql
   */
  async loadProjectData() {
    await this.fetchProjectsPaginated({
      page: this.projectsFilter.pagination.page,
      pageSize: this.projectsFilter.pagination.rowsPerPage,
      sortField: this.projectsFilter.sortField,
      sortOrder: this.projectsFilter.sortOrder,
      filters: { ...this.projectsFilter.filters },
    });
    this.filteredProjects = this.getProjects;
  },

  async created() {
    try {
      await this.$store.dispatch('fetchTimeTrackingsByUser', {
        userId: this.user.id,
        page: 1,
        pageSize: 10,
      });
    } catch (error) {
      console.error('Fehler beim Abrufen der Zeitverfolgungsdaten:', error);
    }
    this.loadTimeTrackingData();
  },

  async mounted() {
    await this.fetchEnumValues('TimeTrackingState');

    if (this.isProjectContext === false) {
      await this.loadProjectData();
    }
  },
};
</script>

<style scoped>
.p-datatable-tbody > tr > td {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.p-datatable-tbody > tr > td .p-column-title {
  display: none;
}
</style>
