<template>
  <div class="mobile-item-select">
    <div class="table-header">
      <div class="table-header-left">
        <h4 class="page-title">{{ $t('item_inventory') }}</h4>
      </div>
    </div>

    <div class="p-inputgroup">
      <span class="p-inputgroup-addon"> </span>
      <InputText placeholder="Suche Material" v-model="filterInput" @input="onFilterInput" />
    </div>

    <div v-for="item in items" :key="item.number" class="item">
      <div class="title" v-html="item.description"></div>
      <div class="item-add-remove-grid">
        <div>
          <InputText
            type="text"
            v-model="item.addRemoveCount"
            class="mr-2"
            style="max-width:100px"
          />
          <strong>{{ $t(item.unit) }}</strong>
        </div>

        <button class="btn btn-lg btn-success mr-2" @click.prevent="onAddItemToProject(item)">
          <i class="fa fa-plus" /> Hinzufügen
        </button>
        <button class="btn btn-lg btn-danger" @click.prevent="onRemoveItemFromProject(item)">
          <i class="fa fa-minus" /> Entnehmen
        </button>
      </div>
    </div>

    <div v-if="!isLoading && items.length === 0" class="not-found">
      <strong>Artikel nicht gefunden!</strong>
    </div>
    <div v-if="isLoading" class="loading">
      <strong>{{ $t('loading') }}</strong>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { FilterMatchMode } from 'primevue/api';
import InputText from 'primevue/inputtext';

export default {
  name: 'MobileItemSelect',
  components: {
    InputText,
  },
  props: {
    itemType: { type: String, default: 'STANDARD' },
  },
  data() {
    return {
      filterInput: '',
      defaultFilters: {
        number: { value: null, matchMode: FilterMatchMode.EQUALS },
        inventory: { value: null, matchMode: FilterMatchMode.EQUALS },
        unit: { value: null, matchMode: FilterMatchMode.EQUALS },
        price: { value: null, matchMode: FilterMatchMode.EQUALS },
        wholesaleNr: { value: null, matchMode: FilterMatchMode.CONTAINS },
        description: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
      tableState: {
        pagination: { skip: 0, pageSize: 1000 },
        sorting: { sortField: 'number', sortOrder: 1 },
        filters: this.defaultFilters,
        filterName: 'itemselect-table-filters',
      },
    };
  },
  computed: {
    ...mapGetters(['isLoading', 'getItems', 'getItemsByType', 'getItemCount']),
    items() {
      return this.getItems;
    },
    pageOffset() {
      return this.tableState.pagination.skip * this.tableState.pagination.pageSize;
    },
    defaultSorting() {
      return { sortField: 'number', sortOrder: 1 };
    },
    defaultPagination() {
      return { skip: 0, pageSize: 10 };
    },
  },
  methods: {
    ...mapActions(['fetchItemsByType', 'fetchItems', 'fetchItemsPaginated']),
    onAddItemToProject(item) {
      this.$emit('item-select:add-item', item);
    },
    onRemoveItemFromProject(item) {
      this.$emit('item-select:remove-item', item);
    },

    async onPage(event) {
      this.tableState.pagination = { skip: event.page, pageSize: event.rows };
      this.loadTableData();
    },
    async onSort(event) {
      this.tableState.sorting = { sortField: event.sortField, sortOrder: event.sortOrder };
      this.loadTableData();
    },
    async onFilter(event) {
      this.loadTableData();
    },
    async onFilterInput() {
      this.tableState.filters.description.value = this.filterInput || '';
      await this.loadTableData();
    },

    async onRemoveFilters(event) {
      this.tableState.sorting = this.defaultSorting;
      this.tableState.pagination = this.defaultPagination;
      this.tableState.filters = this.defaultFilters;

      localStorage.removeItem(this.tableState.filterName);
      await this.loadTableData();
    },

    async onStateRestore(event) {
      if (this.tableState) {
        this.tableState.sorting.sortField = event.sortField
          ? event.sortField
          : this.defaultSorting.sortField;
        this.tableState.sorting.sortOrder = event.sortOrder
          ? event.sortOrder
          : this.defaultSorting.sortOrder;
        this.tableState.pagination.skip = 0;
        this.tableState.pagination.pageSize = event.rows;
      }
    },

    async loadTableData() {
      await this.fetchItemsPaginated({
        pagination: this.tableState.pagination,
        sorting: this.tableState.sorting,
        filters: { ...this.tableState.filters },
      });
    },
  },
  async created() {
    this.tableState.filters = { ...this.defaultFilters };
  },
  async mounted() {
    await this.loadTableData();
  },
};
</script>

<style scoped lang="scss">
.item-select {
  padding: 10px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}

.item-add-remove-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.item-add-remove-grid > div {
  flex: 1 1 100%; /* Make sure the input and unit span take full width on small screens */
}

.item-add-remove-grid > button {
  flex: 1 1 auto; /* Make sure the buttons take auto width on larger screens */
}

@media only screen and (max-width: 600px) {
  .table-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .item-add-remove-grid {
    flex-direction: column;
  }

  .item-add-remove-grid > div,
  .item-add-remove-grid > button {
    flex: 1 1 100%; /* Make sure both the div and buttons take full width on small screens */
  }
}
</style>
