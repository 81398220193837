<template>
  <div class="mobileBarcodeScanner">
    <div class="grid">
      <h4>{{ $t('items') }} {{ $t('add') }} / {{ $t('cull') }}</h4>

      <div class="p-inputgroup" :class="{ 'success-border': displaySuccess }">
        <Button @click="onStart" v-if="!scanInProgress" class="p-button-primary p-button-lg">
          <i class="fa fa-repeat fa-lg"></i
        ></Button>
        <Spinner class="mr-2 mt-3" :size="30" v-if="scanInProgress" />
        <span class="p-inputgroup-addon">
          <i class="fa fa-barcode fa-lg"></i>
        </span>
        <InputText placeholder="Barcode" v-model="detectedCode" @input="onBarcodeInput" />
      </div>

      <div class="item" :class="{ 'success-border': displaySuccess }" v-if="item">
        <div class="title" v-html="item.description" />
        <div class="item-add-remove-grid">
          <div>
            #<strong>{{ item.wholesaleNr }}</strong>
          </div>
          <div>
            Bestand <strong>{{ item.inventory }}</strong>
          </div>
          <div>
            Einheit <strong>{{ $t(item.unit) }}</strong>
          </div>

          <InputText
            type="text"
            v-model="item.addRemoveCount"
            class="mr-2"
            style="max-width:100px"
          />
          <button class="btn btn-lg btn-success mr-2" @click.prevent="onAddItemToInventory(item)">
            <i class="fa fa-plus" /> Hinzufügen
          </button>
          <button class="btn btn-lg btn-danger " @click.prevent="onRemoveItemFromInventory(item)">
            <i class="fa fa-minus" /> Entnehmen
          </button>
        </div>
      </div>
      <div class="not-found" v-if="!scanInProgress && !item">
        <strong>Artikel nicht gefunden!</strong>
      </div>

      <div id="camera" :class="{ cameraHidden: !scanInProgress }"></div>
      <!-- 
      <div class="result" v-if="detected">
        <div><strong> Code:</strong> {{ detected.codeResult.code }}</div>
        <div>
          <strong> Typ:</strong>
          {{ detected.codeResult.format }}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Button from 'primevue/button';
import Quagga from '@ericblade/quagga2';
import Spinner from '@/components/Spinner.vue';
import InputText from 'primevue/inputtext';

export default {
  name: 'MobileTimeTracking',
  components: {
    Button,
    Spinner,
    InputText,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {
      detected: null,
      processed: null,
      videoTrack: null,
      scanInProgress: false,
      detectedCode: '',
      scanner: null,
      displaySuccess: false,
    };
  },
  computed: {
    ...mapGetters(['getItemByBarcode']),
    item() {
      return this.getItemByBarcode(this.detectedCode);
    },
  },

  methods: {
    ...mapActions(['fetchItemByBarcode', 'updateItem']),
    /** */
    async onAddItemToInventory(itemData) {
      console.log('onAddItemToInventory()', parseInt(itemData.addRemoveCount), itemData);
      itemData.inventory += parseInt(itemData.addRemoveCount);
      await this.updateItem(itemData);
    },

    /** */
    async onRemoveItemFromInventory(itemData) {
      console.log('onRemoveItemFromInventory()', parseInt(itemData.addRemoveCount), itemData);
      itemData.inventory -= parseInt(itemData.addRemoveCount);
      await this.updateItem(itemData);
    },

    async camera() {
      const streamLabel = Quagga.CameraAccess.getActiveStreamLabel();
      this.videoTrack = Quagga.CameraAccess.getActiveTrack();
      const devices = await Quagga.CameraAccess.enumerateVideoDevices();
      console.log('camera()', this.videoTrack, streamLabel);
    },
    async onBarcodeInput(input) {
      console.log('onBarcodeInput()', input);

      if (input && input.length >= 6) {
        await this.fetchItemByBarcode(input);
        this.deactivateScanner();
      }
    },
    onStart(e) {
      // console.log('onStart()', e);
      // this.activateScanner();
      this.configureScanner();
      // Quagga.start();
    },
    onStop(e) {
      console.log('onStop()', e);
      // Quagga.stop();
    },
    onProcessed(result) {
      var drawingCtx = Quagga.canvas.ctx.overlay,
        drawingCanvas = Quagga.canvas.dom.overlay;

      if (result) {
        if (result.boxes) {
          drawingCtx.clearRect(
            0,
            0,
            parseInt(drawingCanvas.getAttribute('width')),
            parseInt(drawingCanvas.getAttribute('height'))
          );
          result.boxes
            .filter(function(box) {
              return box !== result.box;
            })
            .forEach(function(box) {
              Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, {
                color: '#e57021',
                lineWidth: 2,
              });
            });
        }

        if (result.box) {
          Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, {
            color: '#209e63',
            lineWidth: 2,
          });
        }

        if (result.codeResult && result.codeResult.code) {
          Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, {
            color: '#0a884f',
            lineWidth: 3,
          });
        }
      }

      // console.log('onProcessed()', result);
      this.processed = result;
    },
    onDetected(result) {
      console.log('onDetected()', result.codeResult.code, result.codeResult.format);
      this.detected = result;
      this.detectedCode = this.detected.codeResult.code;
      this.displaySuccess = true;
      this.onBarcodeInput(this.detectedCode);
      setTimeout(() => {
        this.displaySuccess = false;
      }, 5000);

      // this.deactivateScanner();
    },
    init(err) {
      if (err) {
        console.log(err);
        return;
      }
      // Quagga.onProcessed(this.onProcessed);
      // Quagga.onDetected(this.onDetected);

      console.log('Initialization finished. Ready to start');
      // Quagga.start();
    },

    async deactivateScanner() {
      this.scanInProgress = false;
      Quagga.offProcessed(this.onProcessed);
      Quagga.offDetected(this.onDetected);
    },

    async activateScanner(err) {
      if (err) {
        console.log(err);
        return;
      }
      this.detectedCode = '';
      this.scanInProgress = true;

      console.log('activateScanner()');
      Quagga.onProcessed(this.onProcessed);
      Quagga.onDetected(this.onDetected);
      Quagga.start();
    },

    async configureScanner() {
      await Quagga.init(
        {
          inputStream: {
            name: 'Live',
            type: 'LiveStream',
            target: document.querySelector('#camera'),
            constraints: {
              // width: 400,
              // height: 350,
              facingMode: 'environment',
            },
          },
          decoder: {
            readers: ['ean_reader', 'code_39_reader'],
          },
          halfSample: true,
          locate: true,
          frequency: 10,
          numOfWorkers: 4,
        },
        this.activateScanner
      );
    },
  },
  async mounted() {
    // await this.configureScanner();
    // await this.configureImageScanner();
    // this.activateScanner();
  },
};
</script>

<style lang="scss">
.grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5em;
  margin: 1em;
}

.result {
  border: 1px solid #ccc;
  background-color: #eee;
  padding: 1em;
}

.form-grid-container-left {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 1em;
  row-gap: 1em;
}
.form-grid-container-left .label {
  text-align: right;
}
.success-border {
  border: 4px solid #00ff00 !important;
  opacity: 1;
  transition: opacity 2s ease-out;
}

.item-add-remove-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 1em;
}

.item,
.not-found {
  border: 1px solid #ccc;
  padding: 0.5em;
}

.item .title {
  font-weight: 600;
}

// .cameraHidden {
//   visibility: hidden;
//   display: none;
// }

.p-inputgroup {
  height: 60px;
}

#camera {
  width: 100%;
  position: relative;
}

#camera video,
#camera canvas.drawingBuffer {
  width: 100%;
}

canvas.drawing,
canvas.drawingBuffer {
  position: absolute;
  left: 0;
  top: 0;
}
</style>
