<template>
  <div class="project-material" v-if="project">
    <div class="tabs-container">
      <b-tabs fill class="full-width-tabs">
        <b-tab :title="$t('item_inventory')" active>
          <div class="tab-content">
            <b-row>
              <b-col lg="12" md="12" sm="12" v-if="project.items">
                <hr />
                <MobileItemSelect
                  v-on:item-select:add-item="onAddItemToProject"
                  v-on:item-select:remove-item="onRemoveItemFromProject"
                />
              </b-col>
            </b-row>
          </div>
        </b-tab>
        <b-tab :title="$t('project_items')">
          <div class="tab-content">
            <b-row>
              <b-col lg="12" md="12" sm="12">
                <hr />
                <MobileProjectItems :items="items" />
              </b-col>
            </b-row>
          </div>
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import MobileProjectItems from '@/views/Projects/MobileProjectItems';
import MobileItemSelect from '@/views/Projects/MobileItemSelect';
import { yesNo } from '@/helpers/enums';
import { getYesNoStateColor } from '@/helpers/colors';

export default {
  components: {
    MobileProjectItems,
    MobileItemSelect,
  },
  props: {
    project: { type: Object },
  },
  data() {
    return {};
  },
  computed: {
    items() {
      const itemType = 'STANDARD';
      return this.project.items
        ? this.project.items.filter((item) => item.item.type === itemType)
        : [];
    },
    consumablesDeliveredAt: {
      get() {
        return this.$calendarFormat(this.project.consumablesDeliveredAt);
      },
      set(value) {
        this.project.consumablesDeliveredAt = value;
      },
    },
    yesNo,
  },
  methods: {
    ...mapActions([
      'uploadProjectAttachment',
      'deleteProjectAttachment',
      'buildConsumablesList',
      'fetchConsumablesListAttachmentsBuildState',
      'fetchProjectOfferAttachmentsBuildState',
      'buildProjectOffer',
      'updateProject',
    ]),

    async onDeleteProjectAttachment(event) {
      // await this.save();
      await this.deleteProjectAttachment({
        projectId: this.project.id,
        attachmentId: event.attachmentId,
        attachmentType: event.attachmentType,
      });
    },

    /** */
    async onAddItemToProject(itemData) {
      // console.log('onAddItemToProject()', parseInt(itemData.addRemoveCount), itemData);
      const addRemoveCount = parseInt(itemData.addRemoveCount);
      const item = this.project.items.find((item) => item.item._id == itemData._id);
      if (item) {
        item.count += addRemoveCount;
      } else {
        const newItem = {
          count: addRemoveCount,
          item: itemData,
        };
        this.project.items.push(newItem);
      }
      await this.updateProject(this.project);
    },

    /** */
    async onRemoveItemFromProject(itemData) {
      const addRemoveCount = parseInt(itemData.addRemoveCount);
      const item = this.project.items.find((item) => item.item._id == itemData._id);
      if (item) {
        item.count -= addRemoveCount;
        if (item.count <= 0) {
          const index = this.project.items.findIndex((item) => item.item._id == itemData._id);
          // console.log(index);
          this.project.items.splice(index, 1);
        }
      }
      await this.updateProject(this.project);
    },

    /** */
    calculateItemTotalCost() {
      if (!this.project.items) return 0;
      const totalCost = this.project.items.reduce((acc, curr) => {
        return acc + curr.count * curr.item.price;
      }, 0);

      return totalCost;
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateConsumablesListBuildState() {
      this.fetchConsumablesListAttachmentsBuildState(this.project.number);
      return this.project.consumablesListAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startConsumablesListBuild() {
      if (this.project) {
        await this.buildConsumablesList(this.project.id);
      }
    },

    /**
     * Fetch build state from server (get's called once per second)
     * Is provided to ButtonWait as callback function
     */
    updateProjectOfferBuildState() {
      this.fetchProjectOfferAttachmentsBuildState(this.project.number);
      return this.project.projectOfferAttachmentsBuildState;
    },

    /**
     * Start pdf build on server
     * Is provided to ButtonWait as callback function
     */
    async startProjectOfferBuild() {
      if (this.project) {
        await this.buildProjectOffer(this.project.id);
      }
    },

    getYesNoStateColor,
  },

  async mounted() {
    // after upload is complete emit event to clear dropzone preview
    this.$store.subscribeAction({
      after: (action, state) => {
        if (action.type === 'uploadProjectAttachment') {
          this.isAttachmentUploadComplete = true;
        }
      },
    });
  },
};
</script>
<style scoped>
.tabs-container {
  position: sticky;
  top: 0;
  z-index: 1000;
  background: white;
}
.full-width-tabs .nav {
  display: flex;
  justify-content: center;
  width: 100%;
}
.full-width-tabs .nav-item {
  flex: 1;
  text-align: center;
}
.tab-content {
  padding-top: 1rem;
}
</style>
