<template>
  <div class="project-items">
    <div class="table-header">
      <div class="table-header-left">
        <h4 class="page-title">{{ $t('project_items') }}</h4>
      </div>
    </div>

    <div v-if="loading" class="loading">
      {{ $t('loading') }}
    </div>

    <div v-else>
      <div v-if="items.length === 0" class="not-found">
        {{ $t('no_data_found') }}
      </div>

      <div v-else>
        <div
          v-for="(item, index) in items"
          :key="item.wholesaleNr || item.item.wholesaleNr || index"
          class="item"
        >
          <div class="item-details">
            <div class="title" v-html="item.description || item.item.description"></div>
            <div class="details">
              <strong>{{ $t('count') }}:</strong> {{ item.count }}
              {{ $t(item.unit || item.item.unit) }}
              <span v-if="item.wholesaleNr || item.item.wholesaleNr" class="art-number">
                <strong>{{ $t('itemNumber') }}:</strong>
                {{ item.wholesaleNr || item.item.wholesaleNr }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ProjectItems',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  computed: {
    ...mapGetters(['isLoading']),
  },
  created() {
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
.project-items {
  padding: 10px;
}
.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #f9f9f9;
}
.item-details {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
}
.loading,
.not-found {
  text-align: center;
  margin-top: 20px;
}
@media only screen and (max-width: 600px) {
  .table-header {
    flex-direction: column;
    align-items: flex-start;
  }
  .item-details {
    grid-template-columns: 1fr;
  }
}
</style>
