<template>
  <div>
    <b-tabs fill v-model="tabIndex">
      <b-tab :title="$t('appointments')">
        <div class="grid">
          <div v-if="getAppointments.length <= 0" style="margin: 0 auto;">
            <h4>{{ $t('no_current_appointments') }}</h4>
          </div>
          <div v-for="(appointment, index) in getAppointments" :key="index">
            <div @click="onSelectAppointment(appointment)">
              <Card :class="{ selected: isCardSelected(appointment) }">
                <template #title>
                  {{ $dayjs(appointment.installationStartAt).format('dddd') }}
                  {{ $datetime(appointment.installationStartAt) }} -
                  {{ appointment.project.customer.firstname }}
                  {{ appointment.project.customer.lastname }}
                  <i v-if="isCardSelected(appointment)" class="fa fa-check-square fa-lg ml-1"></i>
                </template>
                <template #content>
                  <div class="flex">
                    <div>
                      <div class="card-grid-container">
                        <div>Name</div>
                        <div>
                          {{ appointment.project.customer.lastname }},
                          {{ appointment.project.customer.firstname }}
                        </div>

                        <div>Adresse</div>
                        <div>
                          {{ appointment.project.customer.street }}
                          {{ appointment.project.customer.streetNumber }},
                          {{ appointment.project.customer.zip }}
                          {{ appointment.project.customer.city }}
                        </div>
                        <div>{{ $t('phone') }}</div>
                        <div>
                          <a :href="'tel:' + appointment.project.customer.phone">{{
                            appointment.project.customer.phone
                          }}</a>
                        </div>
                        <div>{{ $t('mobile') }}</div>
                        <div>
                          <a :href="'tel:' + appointment.project.customer.mobile">{{
                            appointment.project.customer.mobile
                          }}</a>
                        </div>
                        <div>EVU</div>
                        <div v-if="appointment.project.powerCompany">
                          {{ appointment.project.powerCompany.company }}
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="card-grid-container">
                        <div>{{ $t('installationStartAt') }}</div>
                        <div>
                          {{ $time(appointment.installationStartAt) }}
                        </div>
                        <div>{{ $t('installationEndAt') }}</div>
                        <div>
                          {{ $time(appointment.installationEndAt) }}
                        </div>
                        <div>{{ $t('appointmentType') }}</div>
                        <div>
                          <b-badge :variant="getAppointmentTypeColor(appointment.appointmentType)">
                            {{ $t(appointment.appointmentType) }}
                          </b-badge>
                        </div>
                        <div>{{ $t('appointmentState') }}</div>
                        <div>
                          <b-badge
                            :variant="getAppointmentStateColor(appointment.appointmentState)"
                          >
                            {{ $t(appointment.appointmentState) }}
                          </b-badge>
                        </div>
                        <div>{{ $t('confirmed') }}</div>
                        <div>
                          <b-badge
                            :variant="getYesNoStateColor(appointment.appointmentApprovedState)"
                          >
                            {{ $t(appointment.appointmentApprovedState) }}
                          </b-badge>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <strong>{{ $t('notes') }}</strong>
                      </div>
                      <div>
                        {{ appointment.description }}
                      </div>
                      <div class="mt-2">
                        <strong>{{ $t('installationTeamNotes') }}</strong>
                      </div>
                      <div
                        v-if="appointment.project.technicalReview"
                        v-html="appointment.project.technicalReview.installationTeamNotes"
                      ></div>
                    </div>
                    <div>
                      <div>
                        <strong>{{ $t('employees') }}</strong>
                      </div>
                      <div v-for="(employee, index) in appointment.employees" :key="index">
                        {{ employee.firstname }} {{ employee.lastname }}
                      </div>
                      <div class="mt-2">
                        <strong>{{ $t('vehicles') }}</strong>
                      </div>
                      <div v-for="(itemStorage, index) in appointment.itemStorages" :key="index">
                        {{ itemStorage.name }}
                      </div>
                    </div>
                  </div>

                  <div class="flex mt-2" v-if="appointment.project.technicalReview">
                    <div>
                      <div>
                        <strong>{{ $t('dcNotes') }}</strong>
                      </div>
                      <div>
                        <span v-html="appointment.project.technicalReview.dcNotes"> </span>
                      </div>
                    </div>
                    <div>
                      <div>
                        <strong>{{ $t('acNotes') }}</strong>
                      </div>
                      <div>
                        <span v-html="appointment.project.technicalReview.acNotes"> </span>
                      </div>
                    </div>
                  </div>
                </template>
              </Card>
            </div>
          </div>
        </div>
      </b-tab>

      <b-tab :title="$t('project')">
        <b-row>
          <b-col>
            <multiselect
              :placeholder="$t('select_customer')"
              v-model="project"
              :options="filteredProjects"
              :allow-empty="false"
              track-by="number"
              :deselectLabel="$t('deselectLabel')"
              selectLabel=""
              selectedLabel=""
              :internal-search="false"
              :show-labels="true"
              v-on:input="onProjectSelect"
              v-on:search-change="onProjectSearch"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.customer.lastname }}, {{ option.customer.firstname }},
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.customer.lastname }}, {{ option.customer.firstname }},
                {{ option.customer.street }} {{ option.customer.streetNumber }},
                {{ option.customer.zip }}
                {{ option.customer.city }}
              </template>
            </multiselect>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <Widget
              v-if="project"
              class="mt-2 mb-0"
              :title="$t('customer') + ' #' + project.number.toString().padStart(4, '0')"
              collapse
            >
              <div class="card-grid-container">
                <div>Name</div>
                <div>{{ project.customer.lastname }}, {{ project.customer.firstname }}</div>

                <div>Adresse</div>
                <div>
                  {{ project.customer.street }} {{ project.customer.streetNumber }},
                  {{ project.customer.zip }} {{ project.customer.city }}
                </div>
                <div>{{ $t('phone') }}</div>
                <div>
                  <a :href="'tel:' + project.customer.phone">{{ project.customer.phone }}</a>
                </div>
                <div>{{ $t('mobile') }}</div>
                <div>
                  <a :href="'tel:' + project.customer.mobile">{{ project.customer.mobile }}</a>
                </div>
                <div>EVU</div>
                <div v-if="project.powerCompany">{{ project.powerCompany.company }}</div>

                <div v-if="project.technicalReview">
                  {{ $t('installationTeamNotes') }}
                </div>
                <div
                  v-if="project.technicalReview"
                  v-html="project.technicalReview.installationTeamNotes"
                ></div>

                <div v-if="project.technicalReview">
                  {{ $t('dcNotes') }}
                </div>
                <div>
                  <span v-if="project.technicalReview" v-html="project.technicalReview.dcNotes">
                  </span>
                </div>

                <div v-if="project.technicalReview">
                  {{ $t('acNotes') }}
                </div>
                <div>
                  <span v-if="project.technicalReview" v-html="project.technicalReview.acNotes">
                  </span>
                </div>
              </div>
            </Widget>

            <Widget
              v-if="project"
              class="mt-2 mb-0"
              :title="$t('installationImageAttachments')"
              collapse
              collapsed
            >
              <AttachmentGallery
                :attachments="project.installationImageAttachments"
                attachmentType="installationImageAttachments"
                :deletable="false"
                :sortByName="false"
              ></AttachmentGallery>
            </Widget>

            <Widget
              v-if="project"
              class="mt-2 mb-0"
              :title="$t('projectDescriptionAttachments')"
              collapse
              collapsed
            >
              <AttachmentList
                :attachments="project.projectDescriptionAttachments"
                attachmentType="projectDescriptionAttachments"
                :deletable="false"
                :sortByName="false"
              ></AttachmentList>
            </Widget>
          </b-col>
        </b-row>
      </b-tab>

      <!-- <b-tab :title="$t('documentation')" v-if="project">
        <MobileProjectsDocumentation :project="project" />
      </b-tab> -->

      <b-tab :title="$t('measurement')" v-if="project">
        <div class="verification-flex m-1">
          <VerificationState
            :project="project"
            verificationStateType="measurementVerificationState"
          />
        </div>

        <MobileProjectsMeasurement :project="project" />
      </b-tab>

      <b-tab :title="$t('acceptance')" v-if="project">
        <div class="verification-flex m-1">
          <VerificationState
            :project="project"
            verificationStateType="substructureVerificationState"
          />
          <VerificationState :project="project" verificationStateType="acVerificationState" />
          <VerificationState :project="project" verificationStateType="dcVerificationState" />
        </div>

        <MobileProjectsReport :project="project" />
      </b-tab>

      <b-tab :title="$t('serviceReport')" v-if="project">
        <div class="verification-flex m-1">
          <VerificationState :project="project" verificationStateType="serviceVerificationState" />
        </div>

        <MobileProjectsServiceReport :project="project" />
      </b-tab>

      <b-tab :title="$t('time_tracking')" v-if="project">
        <MobileTimeTracking :project="project" :appointment="selectedAppointment" />
      </b-tab>

      <b-tab :title="$t('items')">
        <MobileBarcodeScanner />
      </b-tab>

      <b-tab :title="$t('posts')" v-if="project">
        <ProjectCardPosts :project="project" />
      </b-tab>
      <b-tab :title="$t('orders')" v-if="project">
        <MobileProjectMaterial :project="project" />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import Multiselect from 'vue-multiselect';
import AttachmentGallery from '@/components/AttachmentGallery';
import AttachmentList from '@/components/AttachmentList';
import Widget from '@/components/Widget/Widget';
import { FilterMatchMode } from 'primevue/api';
// import MobileProjectsDocumentation from './MobileProjectsDocumentation';
import MobileProjectsReport from './MobileProjectsReport';
import MobileProjectsServiceReport from './MobileProjectsServiceReport';
import MobileProjectsMeasurement from './MobileProjectsMeasurement';
import MobileTimeTracking from '../TimeTracking/MobileTimeTracking';
import MobileBarcodeScanner from '../Items/MobileBarcodeScanner';
import MobileProjectMaterial from './MobileProjectMaterial.vue';
import { authorizeByUser } from '@/helpers/userCapabilities';
import ProjectCardPosts from '@/views/Projects/ProjectCardPosts';
import VerificationState from '@/components/VerificationState.vue';

import Card from 'primevue/card';
import {
  getAppointmentTypeColor,
  getAppointmentStateColor,
  getYesNoStateColor,
} from '../../helpers/colors';

export default {
  name: 'MobileProjectsPage',
  components: {
    Multiselect,
    AttachmentGallery,
    AttachmentList,
    Widget,
    // MobileProjectsDocumentation,
    MobileProjectsReport,
    MobileProjectsMeasurement,
    ProjectCardPosts,
    MobileProjectsServiceReport,
    MobileTimeTracking,
    Card,
    MobileProjectMaterial,
    MobileBarcodeScanner,
    VerificationState,
  },
  data() {
    return {
      selectedProject: null,
      selectedAppointment: null,
      number: null,
      appointmentNumber: null,
      tabIndex: 0,
      localStorageKey: 'mobile-project-filters',
      /** filters for project search */
      filteredProjects: [],
      filters: {
        customerLastname: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  computed: {
    ...mapGetters('auth', ['getCurrentUser']),
    ...mapGetters([
      'isLoading',
      'getProjects',
      'getProjectByNumber',
      'getAppointmentByNumber',
      'getUser',
      'getAppointments',
    ]),
    project: {
      get() {
        return this.getProjectByNumber(this.number);
      },
      set(value) {
        this.selectedProject = value;
      },
    },
    user() {
      return this.getUser(this.getCurrentUser.id);
    },
    phone() {
      return this.project.customer.phone;
    },
    authorized() {
      return this.authorizeByUser(this.user, [
        'c.minte@q-p-s.com',
        'sebastian@mertenonline.de',
        'a.gladstone@eway-energy.com',
        'e.sciacca@ssp-elektro.com',
        'p.sciacca@q-p-s.com',
        'd.hackmann@eway-energy.com',
        'a.traunfellner@ssp-elektro.com',
        'd.hopp@eway-energy.com',
        'daniel@hopp.de',
        'w.gunsch@q-p-s.com',
        't.nguyen@q-p-s.com',
        's.tam@ssp-elektro.com',
      ]);
    },
  },
  methods: {
    ...mapActions([
      'fetchProjectListFilteredMobile',
      'fetchProjectList',
      'uploadProjectAttachment',
      'fetchReportTemplateImages',
      'fetchProjectsPaginatedMobile',
      'fetchProjectByNumber',
      'fetchAppointmentPages',
    ]),
    authorizeByUser,
    getAppointmentTypeColor,
    getAppointmentStateColor,
    getYesNoStateColor,

    /**
     *
     */
    async stateRestore() {
      this.getFromLocalStorage();

      if (this.appointmentNumber) {
        this.selectedAppointment = this.getAppointmentByNumber(this.appointmentNumber);
        if (this.selectedAppointment && this.selectedAppointment.project) {
          this.selectedProject = this.selectedAppointment.project;
          await this.fetchProjectByNumber(this.number);
        }
        // console.log(this.selectedAppointment);
      } else if (this.number) {
        await this.fetchProjectByNumber(this.number);
      }
    },

    isCardSelected(appointment) {
      return this.selectedAppointment && appointment.number === this.selectedAppointment.number
        ? true
        : false;
    },

    async onSelectAppointment(appointment) {
      this.number = appointment.project.number;
      this.selectedAppointment = appointment;
      this.selectedProject = appointment.project;
      this.appointmentNumber = appointment.number;
      // console.log('onSelectAppointment', this.project.number, this.selectedProject.number);
      await this.fetchProjectByNumber(this.number);
      this.saveToLocalStorage();
    },

    async onProjectSelect(project) {
      this.number = project.number;
      // console.log('onProjectSelect', this.project.number, this.selectedProject.number);
      await this.fetchProjectByNumber(this.number);
      this.saveToLocalStorage();
    },

    async onProjectSearch(rawQuery) {
      const query = rawQuery.toLowerCase();
      this.filters.customerLastname.value = query;
      await this.loadProjectData();
      // console.log('onProjectSearch', this.filters.customerLastname.value);
      this.saveToLocalStorage();
    },

    /** */
    async loadProjectData() {
      await this.fetchProjectsPaginatedMobile({
        filters: { ...this.filters },
      });
      this.filteredProjects = this.getProjects;

      /** make sure current project is in project list */
      if (this.project) {
        this.filteredProjects.push(this.project);
      }
      // console.log('loadProjectData', this.project);
    },

    /**
     * save values to local browser storage
     */
    saveToLocalStorage() {
      localStorage.setItem(
        this.localStorageKey,
        JSON.stringify({
          filters: this.filters,
          projectNumber: this.number,
          appointmentNumber: this.selectedAppointment ? this.selectedAppointment.number : null,
        })
      );
    },

    /**
     * Update state with values from local broswer storage
     */
    getFromLocalStorage() {
      const storage = JSON.parse(localStorage.getItem(this.localStorageKey));
      if (!storage) {
        return;
      }

      this.number = storage.projectNumber ? storage.projectNumber : null;
      this.appointmentNumber = storage.appointmentNumber ? storage.appointmentNumber : null;
      if (storage.filters) {
        this.filters = storage.filters;
      }
    },
  },

  async mounted() {
    await this.fetchReportTemplateImages();
    await this.loadProjectData();

    /**  get appointments for current user reaching 2 weeks back */
    await this.fetchAppointmentPages({
      page: 0,
      pageSize: 20,
      sortField: 'installationStartAt',
      sortOrder: 1,
      filters: {
        employeeUserId: { value: this.getCurrentUser.id, matchMode: FilterMatchMode.EQUALS },
        installationStartAt: {
          value: this.$dayjs().startOf('day'),
          matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO,
        },
      },
    });

    await this.stateRestore();
  },
};
</script>

<style lang="scss" scoped>
.card-grid-container {
  display: grid;
  grid-template-columns: auto 1fr;
  row-gap: 0.5em;
  column-gap: 2em;
}

.card-grid-container div:nth-child(odd) {
  font-weight: bold;
}

/** Mobile Tabs */
.tab-content > .tab-pane {
  padding: 0.5rem 0;
}

.grid {
  display: grid;
  grid-template-columns: 1fr;
  // gap: 0.1em;
}

.grid > div {
  padding: 0.5em;
  border: 2px;
  // background: linear-gradient(180deg, #30323d 0%, #2e3542 100%);
}

::v-deep .p-card {
  border: 1px solid $gray-200;
}

.p-card:hover {
  background: rgba(228, 233, 222, 0.5);
}
.p-card.selected {
  background: rgb(218, 238, 195);
}

.flex {
  display: flex;
  gap: 1.5em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.flex > div {
  flex: 1;
  // border: 1px solid rebeccapurple;
  min-width: 180px;
}

.fa-check-square {
  color: $primary;
}

.verification-flex {
  display: flex;
  gap: 1em;
  flex-flow: row wrap;
  flex-basis: 100%;
  justify-content: flex-start;
}

.verification-flex > div {
  flex: 1;
  min-width: 300px;
}
</style>
